<template>
  <div v-if="!ShowLoader2">
    <div v-if="!activer" class="flex flex-col items-center justify-center h-screen w-full bg-gray-300">
      <div class="flex flex-col items-center bg-white shadow-lg rounded ml-8 mr-8 p-5 lg:w-1/2 md:3/2">
        <img :src="logo" alt="logo prudential" class="mb-2">
        <p class="text-xl text-gray-600 text-center font-bold mb-4">
          {{ message }}
        </p>
      </div>
      <div class="flex justify-center w-full">
        <div
            class="flex justify-around items-center mr-8 ml-8 font-bold text-white bg-red-600 lg:w-1/2 xs:w-full md:p-3 xs:px-0.5 xs:pt-2 xs:pb-2 md:text-base xs:text-xs ">
          <p>N'hésitez pas a nous contacter</p>
          <p>
            (+237) 6 77 15 30 55 <br>
            (+237) 6 94 78 52 70
          </p>
        </div>
      </div>


    </div>
    <div v-bind:class="!activer?'hidden':''">
      <div class="bg-white text-white">
        <!-- Background color split screen for large screens -->
        <div class="hidden lg:block fixed top-0 left-0 w-1/2 h-full bg-white" aria-hidden="true"/>
        <div v-bind:style="{background:'#586162'}"
             class="hidden lg:block fixed top-0  right-0 w-1/2 h-full" aria-hidden="true"/>

        <header class="relative  max-w-7xl mx-auto  py-6
      lg:bg-transparent lg:grid lg:grid-cols-2 lg:gap-x-16 lg:px-8
      lg:pt-16 lg:pb-10  xs:hidden">
          <div class="max-w-2xl mx-auto flex items-center px-4 lg:max-w-lg lg:w-full lg:px-0">
            <a href="#">
              <span class="sr-only">Workflow</span>
              <img :src="logo" alt="" class="xs:hidden lg:block h-20 w-auto"/>
            </a>
          </div>
        </header>
        <header v-bind:style="{background:'#586162'}"
                class="relative  max-w-7xl mx-auto border-b-0.5 py-6 lg:bg-transparent lg:grid lg:grid-cols-2 lg:gap-x-16 lg:px-8 lg:pt-16 lg:pb-10 lg:hidden">
          <div class="max-w-2xl mx-auto flex items-center px-4
        lg:max-w-lg lg:w-full lg:px-0">
            <a class="w-full" href="#">
              <span class="sr-only">Workflow</span>
              <img :src="logo" alt="" class="h-40 w-full object-contain lg:hidden"/>
            </a>
            <h3 class="font-bold ml-2 md:text-white xs:text-white tracking-wider">{{ navigation[0].name }}</h3>
          </div>
        </header>

        <main class="relative grid grid-cols-1 gap-x-10 max-w-7xl mx-auto
      lg:px-8 lg:grid-cols-2 z-10">
          <h1 class="sr-only">Checkout</h1>

          <section v-bind:class="masquerDiv?'h-screen':''" v-bind:style="{background:allColor.background}"
                   aria-labelledby="summary-heading"
                   class="pt-6 pb-12 md:px-10 lg:max-w-lg
                 lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24  lg:row-start-1
                 lg:col-start-2 ">
            <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
              <h2 v-if="messagePaiement!==''" id="summary-heading" class="text-gray-500 text-center
            mb-4 leading-9 md:text-sm font-bold xs:text-sm bg-white shadow rounded p-3 border-l-4 border-blue-600">
                {{ messagePaiement }}
              </h2>
              <h2 v-if="messageavantPaiement!==''" id="messageavantPaiement" class=" text-center
            mb-4 leading-9 md:text-lg font-medium xs:text-lg ">
                {{ messageavantPaiement }}
              </h2>

              <ul role="list" class="text-sm font-medium divide-y divide-white divide-opacity-80">
                <div v-for="(product, index) in products" :key="index" class="mb-2">
                  <li>
                    <div v-bind:class="!product.activer?'opacity-30':''" class="flex items-center py-3 space-x-1">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           class="flex-none w-8 h-8 rounded-md object-center object-cover " viewBox="0 0 20 20"
                           fill="currentColor">
                        <path
                            d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
                      </svg>
                      <div class="flex-auto space-y-1">
                        <h3 class=" text-sm">
                          {{ product.service }}
                        </h3>
                      </div>
                      <div class="flex-none flex space-x-4 items-center text-base font-medium ">
                        <div>
                          <div>
                            <span v-if="product.Nouveau_prix" class="text-sm">Ancien prix :</span> <span
                              class="text-sm opacity-60 font-bold">{{ product.amount }} XAF</span>
                            <br>
                            <span v-if="product.Nouveau_prix" class="text-sm">Nouveau prix :</span> <span
                              v-if="product.Nouveau_prix"
                              class="text-sm opacity-60 font-bold">{{ product.Nouveau_prix }} XAF</span>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!--                  deux block lors du listing-->
                    <div class="flex m-2 justify-end  space-x-3 ">
                      <div v-bind:class="!product.activer?'opacity-30':''" class="">
                        <h3><span class="opacity-60">Nombre de mois :</span> {{ product.month }}</h3>
                      </div>
                      <div v-bind:class="!product.activer?'opacity-30':''" v-if="!product.changePrice"
                           class="h-max w-0.5 border  bg-white"></div>

                      <div v-if="!initialiser"
                           class="flex m-2 justify-end lg:items-center lg:space-x-3 xs:space-x-0 lg:space-y-0 xs:space-y-1 lg:flex-row xs:flex-col">
                        <div v-bind:class="!product.activer?'opacity-30':''">
                          <button
                              class="flex w-full justify-center items-center space-x-1 bg-white shadow hover:shadow-lg px-2 py-0.5 "
                              v-if="!product.changePrice" @click="product.changePrice=true">
                            <div class="text-xs font-bold text-gray-800 opacity-80">Ajuster</div>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" style="color: #20a8d8"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                              </svg>
                            </div>
                          </button>
                        </div>


                        <div class="w-full">
                          <button @click="product.activer = !product.activer"
                                  class="flex justify-center items-center space-x-1 bg-white shadow hover:shadow-lg px-2 py-0.5">
                            <div class="text-xs font-bold text-gray-800 opacity-80">
                              {{ product.activer ? 'Ignorer cette prime' : 'Payer cette prime' }}
                            </div>
                            <div v-if="product.activer">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" style="color: #20a8d8" viewBox="0 0 20 20"
                                   fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                      clip-rule="evenodd"/>
                              </svg>
                            </div>
                            <div v-if="!product.activer">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" fill="none"
                                   viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                              </svg>
                            </div>

                          </button>
                        </div>
                      </div>

                    </div>
                  </li>
                  <div v-if="product.changePrice" class="flex  space-x-2">
                    <div class="border-b border-gray-300 focus-within:border-white w-full ">
                      <input v-model="product.month" @input="validationInputValut($event,index)" type="number"
                             min="0" name="name" id="name"
                             class="block w-full  border-0 border-b border-transparent bg-gray-50
                           focus:border-white text-gray-700 focus:ring-0 sm:text-sm"
                             placeholder="Inserer un nombre de mois"/>
                    </div>
                    <button
                        @click="product.changePrice=false, ((product.month==='' || product.month===null)?product.month=1:''), product.month===1?product.Nouveau_prix = null:''"
                        type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs
                  font-medium rounded  text-gray-700 capitalize bg-white hover:bg-gray-200
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      valider
                    </button>
                    <button @click="product.changePrice=false, product.Nouveau_prix = null, product.month=1"
                            type="button" class="inline-flex text-white items-center px-2.5 py-1.5 border border-transparent text-xs
                  font-medium rounded capitalize bg-red-400 hover:bg-red-500
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      annuler
                    </button>
                  </div>
                </div>
              </ul>

              <dl class="text-sm font-medium  space-y-6 border-t border-white border-opacity-10 pt-6">
                <div class="flex items-center justify-between">
                  <dt>Total</dt>
                  <dd>{{
                      calculPrice()
                    }} XAF
                  </dd>
                </div>

                <div class="flex items-center justify-between">
                  <dt>Nombre totale de primes</dt>
                  <dd>{{ products.length }}</dd>
                </div>

                <div v-bind:style="{textColor:allColor.textPageColor}"
                     class="flex items-center justify-between border-t border-white border-opacity-10 pt-6">
                  <dl>
                    <dt class="text-sm font-medium">Prime</dt>
                    <dd class="mt-1 text-3xl font-extrabold ">
                      {{
                        calculPrice()
                      }} XAF
                    </dd>
                  </dl>
                </div>
              </dl>
            </div>
          </section>

          <section v-bind:class="masquerDiv?'lg:hidden xs:hidden':''" aria-labelledby="payment-and-shipping-heading"
                   class="py-16 lg:max-w-lg lg:w-full lg:mx-auto lg:pt-0
                 lg:pb-24 lg:row-start-1 lg:col-start-1">
            <div>
              <div class="mx-auto px-4  lg:px-0">
                <div>
                  <h3 id="contact-info-heading" class="text-lg tracking-wider font-medium text-gray-900">
                    Formulaire de paiement
                  </h3>

                  <AlertComponent v-if="ShowAlert" class="w-full mt-8" v-on:fermerAlerte="fermerAlert"
                                  :message="messageAlert" :color="colorAlert"/>

                  <div class="mt-6">
                    <label for="email-address"
                           class="block text-sm font-medium tracking-wider text-gray-700">Noms</label>
                    <div class="mt-1">
                      <input v-bind:style="{borderColor:allColor.background}" type="text" disabled :value="form.name"
                             id="email-address" name="email-address" autocomplete="email"
                             class="block w-full uppercase text-gray-700 text-center font-bold tracking-wider bg-indigo-50 border-gray-300 rounded shadow-sm  sm:text-sm"/>
                    </div>
                  </div>
                </div>

                <div class="mt-10">
                  <div class="mt-6 grid grid-cols-3 sm:grid-cols-4 gap-y-6 gap-x-4">
                    <div class="col-span-3 sm:col-span-4">
                      <label for="card-number" class="block text-sm tracking-wider font-medium text-gray-700">Numéro de
                        téléphone</label>
                      <div class="mt-1">
                        <input type="number" id="card-number" v-model="form.mobileno"
                               class="block w-full border-gray-300 text-gray-700 rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="ShowLoader" class="flex flex-col items-center space-y-1 justify-center pt-3">
                  <LoaderComponent />
                  <p class="text-gray-500"> En cours de chargement...</p>
                </div>

                <div class="mt-10 flex justify-end pt-6 border-t border-indigo-300">
                  <button v-bind:style="{backgroundColor:allColor.bgBouton}"
                          v-on:click="submitClick"
                          class="bg-indigo-600 border flex justify-center
              space-x-2 flex-1 border-transparent rounded shadow-sm py-2 px-4
              text-sm font-medium hover:bg-indigo-800 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50
              focus:ring-indigo-500">
                    <h3 class="tracking-widest text-white  cursor-pointer">Payer avec NexahPay</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer text-white" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>

        </main>
        <div class="hidden sm:block sm:absolute inset-0 z-0" aria-hidden="true">
          <svg class="absolute bottom-0 left-0 ml-8  text-indigo-200 " width="264" height="384" viewBox="0 0 364 384"
               fill="none">
            <defs>
              <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20"
                       patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
              </pattern>
            </defs>
            <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"></rect>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div v-if="ShowLoader2" class="flex justify-center items-center h-screen">
    <LoaderComponent2/>
  </div>

</template>

<script>
import LoaderComponent from "../components/LoaderComponent";
import AlertComponent from "../components/AlertComponent";
import LoaderComponent2 from "../components/LoaderComponent2";
import axios from "axios";

const products = [
  // More products...
]

export default {
  data() {
    return {
      data: null,
      nameSercice: 'Paiement service',
      montant: '2500',
      ShowLoader: false,
      ShowLoader2: true,
      activer: null,
      products,
      message: null,
      changePrice: false,
      ShowAlert: false,
      clickPayment: true,
      disabled: false,
      Nouveau_prix: null,
      masquerDiv: false,
      messagePaiement: "",
      messageavantPaiement: "",
      form: {
        services: null,
        mobileno: null,
        name: null,
        code: this.$route.params.code,
        total: null
      },
      initialiser: false,
      services: null,
      logo: null,
      colorAlert: 'bg-blue-400',
      messageAlert: 'Message Alert',
      allColor: {
        background: "#586162",
        bgBouton: "#20a8d8",
        textPageColor: ""
      },
      color: 'green',
      navigation: [
        {name: ``, href: 'Home'},
      ]
    }
  },
  components: {
    LoaderComponent,
    AlertComponent,
    LoaderComponent2
  },
  mounted() {
    axios.get('https://ms-prime-pay.nexah.net/api/v1/org-detail/' + this.$route.params.code)
        .then((response) => {
          this.ShowLoader2 = false
          console.log(response.data)
          this.data = response.data
          this.montant = response.data.data.amount
          this.form.name = response.data.data.name
          this.form.mobileno = response.data.data.mobileno
          this.logo = response.data.data.org.logo
          this.messageavantPaiement = response.data.data.org.message_payment
          this.activer = response.data.data.status === 1
          this.message = response.data.message
          this.products = response.data.data.services.filter(el => {
            el.Nouveau_prix = null
            el.changePrice = false
            el.activer = true
            el.month = 1
            // console.log(el)
            return el
          })
        }).catch((error) => {
      console.log(error.message)
    })
  },
  methods: {
    submitClick() {
      if (this.form.name === "" ||
          this.form.name == null ||
          this.form.mobileno == null
          || this.form.mobileno === "") {
        this.ShowAlert = true
        this.colorAlert = 'bg-red-700'
        this.messageAlert = 'Ne laisser aucun champ vide'
      } else {
        if (this.clickPayment === true) {
          this.clickPayment = false
          this.ShowAlert = false
          this.disabled = true
          this.ShowLoader = true
          //attribuer la nouvelle valeur au total
          this.form.total = this.calculPrice()
          console.log(this.form)
          axios.post("https://ms-prime-pay.nexah.net/api/v1/pay", this.form)
              .then(response => {
                this.clickPayment = true
                if (response.data.data.status !== 2) {
                  this.ShowLoader = false
                  this.ShowAlert = true
                  this.form.name = ''
                  this.form.mobileno = ''
                  this.masquerDiv = true
                  this.messageavantPaiement = ''
                  this.messagePaiement = response.data.message
                  this.messageAlert = response.data.message
                  this.colorAlert = 'bg-red-700'
                  console.log(response.data)
                  this.initialiser = true
                } else {
                  this.ShowLoader = false
                  this.ShowAlert = true
                  this.messageAlert = response.data.message
                  this.colorAlert = 'bg-red-700'
                }
              }).catch((error) => {
            this.clickPayment = true
            this.ShowLoader = false
            this.ShowAlert = true
            this.messageAlert = error.message
            this.colorAlert = 'bg-red-700'
            console.log(error.message)
          })
        }

      }
    },
    calculPrice() {
      let total = 0
      this.products.forEach(el => {
        if (el.Nouveau_prix) {
          if (el.activer) {
            total += Number.parseInt(el.Nouveau_prix)
          }
        } else {
          if (el.activer) {
            total += Number.parseInt(el.amount)
          }
        }
      })

      return total
    },
    fermerAlert() {
      this.ShowAlert = false
    },
    validationInputValut($event, index) {
      // let newValue = Number.parseInt($event.target.value)
      let oldValue = Number.parseInt(this.products[index].amount)
      let month = Number.parseInt(this.products[index].month)
      // console.log(newValue, oldValue, month)
      if (month > 1) {
        this.products[index].Nouveau_prix = (month * oldValue)
      } else {
        this.products[index].Nouveau_prix = null
      }
    }
  },
  updated() {
    const services = {}
    let index = 0
    this.data.data.services.map(data => {
      services[data.id] = {
        'id': data.id,
        'amount': data.activer ? (this.products[index].Nouveau_prix !== null ? this.products[index].Nouveau_prix : data.amount) : 0
      }
      index += 1
    })
    this.form.services = services
    this.calculPrice()
  }
}
</script>