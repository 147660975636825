import { createRouter, createWebHashHistory } from 'vue-router'
import NewHome from "../views/NewHome";
const routes = [
  {
    path: '/:code',
    name: 'Home',
    component: NewHome
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
