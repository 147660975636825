<template>
  <router-view/>
</template>

<script>
import { reactive } from 'vue'
export default {
  name:'App',
  setup() {
    const state = reactive({
      name : null

    })

    return {
      state
    }
  },
  components:{
  }
}
</script>



<style>

</style>
